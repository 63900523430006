ul, ol {
	margin: 0 0 $global_mb;
	padding: 0 0 0 18px;
}

ul {
	list-style: square;
	ul {
		list-style: circle;
	}
}

	li {
		margin: 0;
		padding: 0;
		> ul,
		> ol {
			margin: 5px 0 5px 3px;
		}
	}

.list-note {
	line-height: 1.2;
}